import * as ng from 'angular';
import { BehaviorSubject, Observable } from 'rxjs';

import { ICart } from '../models';

/**
 * Service for handling cart actions.
 */
export class CartService {
    static $inject: string[] = ['$http', '$q', '$window', '$cookies'];

    private _cart$ = new BehaviorSubject<ICart>(null);

    /** Emits when the cart updates */
    cart$: Observable<ICart>;

    /**  */
    private _sessionCartId?: string;
    private _awaiting?: ng.IPromise<ICart>;

    constructor(private $http: ng.IHttpService, private $q: ng.IQService, private $window: Window, private $cookies: ng.cookies.ICookiesService) {
        this.cart$ = this._cart$.asObservable();
        this._sessionCartId = $cookies.get('_cartid_');
    }

    updateQuantity(rowId: number, quantity: number): ng.IPromise<ICart> {
        const defer = this.$q.defer<ICart>();

        if (!this._cart$.value) {
            this.getSessionCart(true).then(
                () => {
                    this.updateQuantity(rowId, quantity).then(defer.resolve, defer.reject);
                },
                error => {
                    defer.reject(error);
                },
            );
            return;
        }

        const cart = this._cart$.value;
        this.$http<ICart>({
            method: 'POST',
            url: '/umbraco/api/cart/updatequantity',
            params: {
                id: cart.id,
                rowId: rowId,
                quantity: quantity,
            },
        }).then(
            result => {
                const cart = result.data;
                this._setCookie(cart);
                defer.resolve(cart);
            },
            e => defer.reject(e),
        );

        return defer.promise;
    }

    getSessionCart(createIfNotExisting: boolean): ng.IPromise<ICart> {
        const sessionCartId = this._sessionCartId;

        if (!sessionCartId) {
            if (createIfNotExisting) {
                return this.create();
            }

            const defer = this.$q.defer<ICart>();
            defer.resolve(null);
            return defer.promise;
        }

        if (this._awaiting) {
            return this._awaiting;
        }

        const defer = this.$q.defer<ICart>();
        this.getCart(sessionCartId).then(
            cart => {
                defer.resolve(cart);
            },
            error => {
                if (createIfNotExisting) {
                    this.create().then(defer.resolve, defer.reject);
                } else {
                    // Remove old cart
                    this.deleteCart();
                    defer.reject(error);
                }
            },
        );

        this._awaiting = defer.promise;
        return defer.promise;
    }

    deleteCart(): void {
        this.$cookies.remove('_cartid_', {
            path: '/',
        });
        this._cart$.next(null);
        this._awaiting = null;
    }

    private _setCookie(cart: ICart): void {
        let date = new Date();
        date.setDate(new Date().getDate() + 365);

        this.$cookies.put('_cartid_', cart.id, {
            path: '/',
            secure: this.$window.location.href.startsWith('https://'),
            expires: date,
            samesite: 'strict'
        } as any);
        this._cart$.next(cart);
    }

    create(): ng.IPromise<ICart> {
        const defer = this.$q.defer<ICart>();

        this.$http<ICart>({
            method: 'POST',
            url: '/umbraco/api/cart/create',
        }).then(
            result => {
                const cart = result.data;
                this._setCookie(cart);
                defer.resolve(cart);
            },
            e => {
                defer.reject(e);
            },
        );

        return defer.promise;
    }

    addItem(itemId: number, quantity: number): ng.IPromise<ICart> {
        const defer = this.$q.defer<ICart>();

        if (!this._cart$.value) {
            this.getSessionCart(true).then(
                () => {
                    this.addItem(itemId, quantity).then(defer.resolve, defer.reject);
                },
                error => {
                    defer.reject(error);
                },
            );
            return defer.promise;
        }

        this.$http<ICart>({
            method: 'POST',
            url: '/umbraco/api/cart/additem',
            params: {
                id: this._cart$.value.id,
                itemId: itemId,
                quantity: quantity,
            },
        }).then(
            result => {
                const cart = result.data;
                this._setCookie(cart);
                defer.resolve(cart);
            },
            error => defer.reject(error),
        );

        return defer.promise;
    }

    getCart(cartId: string): ng.IPromise<ICart> {
        const defer = this.$q.defer<ICart>();

        if (this._cart$.value) {
            defer.resolve(this._cart$.value);
            return defer.promise;
        }

        this.$http<ICart>({
            method: 'GET',
            url: '/umbraco/api/cart/get',
            params: {
                id: cartId,
            },
        }).then(
            result => {
                const cart = result.data;
                this._setCookie(cart);
                defer.resolve(cart);
            },
            error => defer.reject(error),
        );

        return defer.promise;
    }

    update(cart: ICart): ng.IPromise<ICart> {
        const defer = this.$q.defer<ICart>();

        this.$http<ICart>({
            method: 'POST',
            url: '/umbraco/api/cart/update',
            data: cart,
        }).then(
            result => {
                const cart = result.data;
                this._setCookie(cart);
                defer.resolve(cart);
            },
            defer.reject,
        );

        return defer.promise;
    }
}

ng.module('services').service('CartService', CartService);
