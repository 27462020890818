import * as ng from 'angular';

import { IOrder } from '../models';
import { TrackService } from '../services';

interface IConfirmState {
    /** True if the purchase should be tracked, false otherwise */
    track: boolean;

    /** The purchase order to track */
    order: IOrder;
}

/**
 * Controller for the confirmation page
 */
export class ConfirmController implements ng.IController {
    static $inject: string[] = ['TrackService', 'state'];

    constructor(private _trackService: TrackService, private _state: IConfirmState) {}

    $onInit(): void {
        if (this._state.track) {
            this._trackService.trackPurchase(this._state.order);
        }
    }
}

ng.module('shop').controller('ConfirmController', ConfirmController);
