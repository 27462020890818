import * as ng from 'angular';
import { Subscription } from 'rxjs';
import * as UIkit from 'uikit';

import { ICart, ICartItem } from '../models';
import { BulletinService, CartService, TrackService } from '../services';
import { ShopModule } from './module';

/**
 * Scope for the cart controller.
 */
interface ICartControllerScope {
    dirty: boolean;
    cart: ICart;
    loading: boolean;
    quantityModels: { [key: number]: number };
}

/**
 * Controller for displaying the cart and the items in the cart.
 */
class CartController implements ng.IController {
    static $inject: string[] = ['$scope', '$window', 'CartService', 'BulletinService', 'TrackService'];

    /** Active subscriptions */
    private _subscriptions: Subscription[] = [];

    constructor(
        private $scope: ICartControllerScope,
        private $window: Window,
        private _cartService: CartService,
        private _bulletinService: BulletinService,
        private _trackService: TrackService,
    ) {
        this.$scope.dirty = false;
        this.$scope.cart = null;
        this.$scope.loading = true;
        this.$scope.quantityModels = {};
    }

    /** Opens up the cart */
    open(): void {
        UIkit.offcanvas('#cart').show();
    }

    /**
     * Decreases the quantity with 1 for the given item
     * @param item The item to decrease in quantity
     */
    decreaseQuantity(item: ICartItem): void {
        this.$scope.loading = true;
        this.$scope.quantityModels[item.id] = item.quantity - 1;
        this.changeQuantity(item);
    }

    /**
     * Increases the quantity with 1 for the given item
     * @param item The item to increase the quantity for
     */
    increaseQuantity(item: ICartItem): void {
        this.$scope.quantityModels[item.id] = item.quantity + 1;
        this.changeQuantity(item);
    }

    /**
     * Updates the given item with the quantity set in the {quantityModels}.
     * @param item The item to update the quantity for
     */
    changeQuantity(item: ICartItem): void {
        const quantity = this.$scope.quantityModels[item.id];
        if (typeof quantity !== 'number' || isNaN(quantity)) {
            return;
        }

        this.$scope.loading = true;
        this._cartService
            .updateQuantity(item.id, quantity)
            .then(
                () => {
                    const change = item.quantity - quantity;
                    this._trackService.trackQuantityChange(item, change);
                    this.open();
                },
                (error) => this._bulletinService.exception(error),
            )
            .finally(() => {
                this.$scope.loading = false;
                this.$scope.dirty = false;
            });
    }

    /**
     * Redirects the user to the given checkout url
     * @param url The checkout url to redirect the user to
     */
    goToCheckout(url: string): void {
        this._trackService.trackToCheckout(this.$scope.cart).then(() => (this.$window.location.href = url));
    }

    $onInit(): void {
        this._subscriptions.push(
            this._cartService.cart$.subscribe((cart: ICart) => {
                this.$scope.cart = cart;

                this.$scope.quantityModels = {};

                if (this.$scope.cart) {
                    this.$scope.cart.items.forEach((item) => {
                        this.$scope.quantityModels[item.id] = item.quantity;
                    });
                }

                this.$scope.loading = false;
            }),
        );
    }

    $onDestroy(): void {
        this._subscriptions.forEach((sub) => !sub.closed && sub.unsubscribe());
    }
}

ShopModule.controller('CartController', CartController);
