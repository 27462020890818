import * as ng from 'angular';

import { IProductVariantItem } from '../models';

export class ProductService {
    static $inject: string[] = ['$http', '$q'];

    constructor(private $http: ng.IHttpService, private $q: ng.IQService) {}

    get(variantProductId): ng.IPromise<IProductVariantItem> {
        const defer = this.$q.defer<IProductVariantItem>();

        this.$http<IProductVariantItem>({
            method: 'GET',
            url: '/umbraco/api/product/getbyid',
            params: {
                productId: variantProductId,
            },
        }).then(result => {
            defer.resolve(result.data);
        }, defer.reject);

        return defer.promise;
    }

    getByMainProductId(mainProductId): ng.IPromise<IProductVariantItem[]> {
        const defer = this.$q.defer<IProductVariantItem[]>();

        this.$http<IProductVariantItem[]>({
            method: 'GET',
            url: '/umbraco/api/product/getByProductId',
            params: {
                productId: mainProductId,
            },
        }).then(result => {
            defer.resolve(result.data);
        }, defer.reject);

        return defer.promise;
    }
}

ng.module('services').factory('productService', ProductService);
