import * as ng from 'angular';
import * as UIkit from 'uikit';

export class BulletinService {
    static $inject: string[] = [];

    constructor() {}

    warning(message: string): void {
        UIkit.notification(message, { status: 'warning' });
    }

    error(message: string): void {
        UIkit.notification(message, { status: 'danger' });
    }

    success(message: string): void {
        UIkit.notification(message, { status: 'success' });
    }

    primary(message: string): void {
        UIkit.notification(message, { status: 'primary' });
    }

    exception(exception: any): void {
        const message = this._getMessage(exception);

        if (message) {
            UIkit.notification(message, { status: 'danger' });
        }
    }

    private _getMessage(exception: any): string {
        const properties: string[] = ['statusMessage', 'exceptionMessage', 'message', 'error', 'errorMessage'];
        if (typeof exception === 'object') {
            if (!exception) {
                return null;
            }

            if (typeof exception.data === 'object') {
                const message = this._getMessage(exception.data);
                if (message) {
                    return message;
                }
            }

            let msg = null;
            properties.some(prop => {
                if (prop in exception && typeof exception[prop] === 'string') {
                    msg = exception[prop];
                    return true;
                }
                return false;
            });
            return msg || null;
        } else if (typeof exception === 'string') {
            return exception;
        } else if (Array.isArray(exception)) {
            if (exception.length > 0) {
                for (let i = 0; i < exception.length; i++) {
                    const item = exception[i];
                    const msg = this._getMessage(item);
                    if (msg) {
                        return msg;
                    }
                }
            }
        }
    }
}

ng.module('services').service('BulletinService', BulletinService);
