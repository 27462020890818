import * as ng from 'angular';

import { ICart, ICartItem, ICheckout } from '../models';
import { BulletinService, CheckoutService, TrackService } from '../services';

/**
 * The scope settings for the {CheckoutController}.
 */
interface ICheckoutControllerScope {
    /** True if the view is loading, false otherwise */
    loading: boolean;

    /** True if the cart is in a changed state and needs to be updated, false otherwise */
    dirty: boolean;

    /** True if the view has been initialized, false otherwise */
    initialized: boolean;

    /** The current form level errors */
    formErrors: string[];

    /** The checkout model for the view */
    checkout: ICheckout;

    /** The HTML content for the payment service */
    paymentHtml: any;

    /** True if the cart is empty */
    isEmptyCart: boolean;

    /** The current cart for the view  */
    cart: ICart;

    /** The currently updated item ids (key = item id, boolean = dirty status) */
    dirtyItems: { [key: number]: boolean };

    /** The errors per row, key is the row id */
    errors: { [key: number]: { rowId?: number; errorMessage: string }[] };
}

/** The checkout state settings for the initial view */
interface ICheckoutState {
    checkout: ICheckout;
    errorMessage: string | null;
}

export class CheckoutController implements ng.IController {
    static $inject: string[] = ['$scope', '$sce', 'CheckoutService', 'BulletinService', 'TrackService', 'state'];

    private $scope: ICheckoutControllerScope;

    /** The last retrieved cart instance */
    private _lastCart?: ICart;

    constructor(
        $scope: ICheckoutControllerScope,
        private $sce: ng.ISCEService,
        private _checkoutService: CheckoutService,
        private _bulletinService: BulletinService,
        private _trackService: TrackService,
        private _state: ICheckoutState,
    ) {
        this.$scope = $scope;

        this.$scope.loading = true;
        this.$scope.dirty = false;
        this.$scope.errors = {};
        this.$scope.formErrors = [];
        this.$scope.dirtyItems = {};
    }

    $onInit(): void {
        this.$scope.initialized = true;
        this._setCheckout(this._state);
    }

    /**
     * Checks if the current cart is valid.
     * @returns True if the checkout is valid, false otherwise
     */
    isValid(): boolean {
        return !this.$scope.dirty && this.$scope.checkout && this.$scope.checkout.validatedCart.isValid;
    }

    /**
     * Updates the current view with a new state
     * @param state The new state
     */
    private _setCheckout(state: ICheckoutState): void {
        if (!state || !state.checkout?.validatedCart) {
            this.$scope.checkout = null;
            this.$scope.formErrors = [];
            this.$scope.errors = {};
            this.$scope.isEmptyCart = true;
            this._lastCart = null;
        } else {
            this.$scope.checkout = state?.checkout;
            this.$scope.cart = state?.checkout?.validatedCart?.cart || null;
            this.$scope.isEmptyCart = !this.$scope.cart || this.$scope.cart.items.length === 0;
            this.$scope.formErrors = [];
            this.$scope.errors = {};

            if (state?.checkout?.validatedCart) {
                this.$scope.formErrors = state.checkout.validatedCart.validationErrors
                    .filter((error) => {
                        return !error.rowId;
                    })
                    .map((error) => {
                        return error.errorMessage;
                    });
            }

            if (state?.errorMessage) {
                this.$scope.formErrors.push(state.errorMessage);
            }

            if (this.$scope.cart) {
                // Clone cart to keep as original
                this._lastCart = JSON.parse(JSON.stringify(state.checkout.validatedCart.cart));

                this._trackService.trackToCheckout(this.$scope.cart);

                this.$scope.cart.items.forEach((item) => {
                    const errors = state.checkout.validatedCart.validationErrors.filter((error) => {
                        return error.rowId === item.id;
                    });
                    this.$scope.errors[item.id] = errors;
                });
            } else {
                this._lastCart = null;
            }
        }

        this._setHtml();
        this.$scope.loading = false;
    }

    /**
     * Sets the checkout payment html from the current checkout.
     */
    private _setHtml(): void {
        if (this.$scope.checkout && this.$scope.checkout.paymentHtml) {
            this.$scope.paymentHtml = this.$sce.trustAsHtml(this.$scope.checkout.paymentHtml);
        } else {
            this.$scope.paymentHtml = null;
        }
    }

    /**
     * Updates the cart with the currently set quantities.
     */
    update() {
        this.$scope.loading = true;

        if (this._lastCart) {
            this._lastCart.items.forEach((item, index) => {
                const newItem = this.$scope.cart.items[index];
                const quantityChange = item.quantity - newItem.quantity;

                if (quantityChange < 0) {
                    // Removed from cart
                    this._trackService.trackRemoveFromCart(newItem, quantityChange);
                } else if (quantityChange > 0) {
                    this._trackService.trackAddToCart(newItem, quantityChange);
                }
            });
        }

        this._checkoutService.update(this.$scope.cart).then(
            (checkout) => {
                this.$scope.dirtyItems = {};
                this.$scope.loading = false;
                this.$scope.dirty = false;
                this._setCheckout({
                    checkout: checkout,
                    errorMessage: null,
                });
            },
            (error) => {
                this._bulletinService.exception(error);
                this.$scope.loading = false;
            },
        );
    }

    /**
     * Removes an item from the cart
     * @param item The item to remove from the cart
     */
    remove(item: ICartItem) {
        item.quantity = 0;
        this.$scope.dirtyItems[item.id] = true;
        this.$scope.dirty = true;
    }

    /**
     * Decreases the quantity for a given item with 1.
     * Does NOT update the cart.
     * @param item The item to decrease
     */
    decreaseQuantity(item: ICartItem) {
        if (item.quantity <= 0) {
            return;
        }

        item.quantity--;
        this.$scope.dirtyItems[item.id] = true;
        this.$scope.dirty = true;
    }

    /**
     * Increases the quantity for a given item with 1.
     * Does NOT update the cart.
     * @param item The item to increase quantity for
     */
    increaseQuantity(item: ICartItem) {
        item.quantity++;
        this.$scope.dirtyItems[item.id] = true;
        this.$scope.dirty = true;
    }

    /**
     * Updates a given item quantity from the current item model quantity
     * @param item The item to update quantity for
     */
    updateItem(item: ICartItem) {
        this.$scope.dirty = true;

        if (typeof item.quantity === 'string') {
            item.quantity = parseInt(item.quantity);
            this.$scope.dirtyItems[item.id] = true;
        }

        if (typeof item.quantity !== 'number' || isNaN(item.quantity)) {
            item.quantity = 1;
            this.$scope.dirtyItems[item.id] = true;
        }
    }
}

ng.module('shop').controller('CheckoutController', CheckoutController);
