import { FormatService } from '../services';
import { FilterModule } from './module';

export class ShopCurrencyFilter {
    static factory() {
        const factoryFunction = (formatService: FormatService) => {
            return (value: any) => {
                var numValue = value;
                if (typeof value === 'string') {
                    numValue = Number(value);

                    if (isNaN(numValue)) {
                        return '';
                    }
                } else if (value === null || value === undefined) {
                    return '';
                }

                return formatService.formatCurrency(numValue, 0);
            };
        };
        factoryFunction.$inject = ['FormatService'];
        return factoryFunction;
    }
}

FilterModule.filter('shopCurrency', ShopCurrencyFilter.factory());
