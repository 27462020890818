import * as ng from 'angular';

import { IInventoryItem } from '../models';

export class InventoryService {
    static $inject: string[] = ['$http', '$q'];

    constructor(private $http: ng.IHttpService, private $q: ng.IQService) {}

    get(variantProductId: number): ng.IPromise<IInventoryItem> {
        const defer = this.$q.defer<IInventoryItem>();

        this.$http<IInventoryItem>({
            method: 'GET',
            url: '/umbraco/api/inventory/get',
            params: {
                productId: variantProductId,
            },
        }).then(result => {
            defer.resolve(result.data);
        }, defer.reject);

        return defer.promise;
    }

    getByMainProductId(mainProductId: number): ng.IPromise<IInventoryItem[]> {
        const defer = this.$q.defer<IInventoryItem[]>();

        this.$http<IInventoryItem[]>({
            method: 'GET',
            url: '/umbraco/api/inventory/getByMainProduct',
            params: {
                mainProductId: mainProductId,
            },
        }).then(result => {
            defer.resolve(result.data);
        }, defer.reject);

        return defer.promise;
    }
}

ng.module('services').factory('InventoryService', InventoryService);
