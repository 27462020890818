import * as $ from 'jquery';
import * as UIkit from 'uikit';

class Loader {
    private _loader: JQLite;

    constructor() {
        this._loader = $('.app-loader');
    }

    show(): void {
        this._loader.removeClass('hidden');
    }

    hide(): void {
        this._loader.addClass('hidden');
    }
}

const loader = new Loader();
UIkit.slider('#product-slidenav');

$('#product-slidenav a[data-slide]').on('click', function (e) {
    e.preventDefault();
    const index = $(this).data('slide');
    const $slideshow = UIkit.slideshow('#product-slideshow');
    $slideshow.show(index);
});

// Replace embedded media content with a special wrapper
$('.page-content iframe').each((_index, element) => {
    const $wrapper = $('<div class="media-wrapper"/>');
    const $innerWrapper = $('<div class="media-inner-wrapper"/>');
    $wrapper.insertAfter(element);
    $wrapper.append($innerWrapper);
    $innerWrapper.append(element);
});

// Add class to menu on scroll
$(window).on('scroll', (element) => {
    const offset = $(element.currentTarget).scrollTop();
    const $body = $('body');
    const hasClass = $body.hasClass('is-scrolled');

    if (offset === 0) {
        if (hasClass) {
            $body.removeClass('is-scrolled');
        }
    } else if (!hasClass) {
        $body.addClass('is-scrolled');
    }
});

$('.navigation-list').on('change', (e) => {
    const $select = $(e.currentTarget);
    const $option = $select.find('option:selected');

    if ($option.length) {
        const url = $option.data('url');
        if (url && url.startsWith('/')) {
            window.location.href = url;
        }
    }
});

$('a[href^="#"].slidenav').on('click', e => {
    const $element = $(e.currentTarget);
    const $target = $($element.attr('href'));

    if ($target.length) {
        const delay = 250;
        const menuHeight = $('#main-menu').outerHeight();

        e.preventDefault();

        $('html, body').animate({
            scrollTop: $target.offset().top - menuHeight,
        }, delay);
    }
});

$('[data-lazy-modal]').on('click', e => {
    e.preventDefault();

    const $item = $(e.currentTarget);
    const url = $item.attr('data-href');

    loader.show();

    // Load the remote url and display the modal with the url
    $.ajax({
        method: 'GET',
        url: url,
    })
        .then(
            (content) => {
                loader.hide();
                const modal = UIkit.modal(content);

                $(modal.$el).on('hidden.uikit.dom', function () {
                    $(this).remove();
                });

                modal.show();
            },
            () => {
                loader.hide();
            });
});

UIkit.util.on('#modal-search', 'show', function () {
    $(this).find('input').attr('autofocus', 'autofocus');
});
