import * as ng from 'angular';
import * as UIkit from 'uikit';

import { IProductVariantItem } from '../models';
import { BulletinService, CartService, TrackService } from '../services';

interface IProductControllerScope extends ng.IScope {
    model: { quantity: number; productId: number };
    loading: boolean;
    variant?: IProductVariantItem;
    variants: IProductVariantItem[];
    isSellable: boolean;
    variantDescription: string;
    initialized: boolean;
    invalid: boolean;
}

declare global {
    interface Window {
        KlarnaOnsiteService: {
            push(obj: { eventName: string });
        };
    }
}

interface IProductState {
    variants: IProductVariantItem[];
    isSellable: boolean;
}

class ProductController implements ng.IController {
    static $inject: string[] = ['$scope', '$location', '$sce', 'CartService', 'BulletinService', 'TrackService', 'product'];

    constructor(
        private $scope: IProductControllerScope,
        private $location: ng.ILocationService,
        private $sce: ng.ISCEService,
        private _cartService: CartService,
        private _bulletinService: BulletinService,
        private _trackService: TrackService,
        product: IProductState,
    ) {
        this.$scope.model = {
            quantity: 1,
            productId: $location.search().variant || (product.variants.length === 1 ? product.variants[0].id : null),
        };

        this.$scope.loading = false;

        this.$scope.variant = null;
        this.$scope.variantDescription = null;
        this.$scope.variants = product.variants;
        this.$scope.invalid = false;
        this.$scope.initialized = true;

        this.$scope.isSellable = product.isSellable;
    }

    $onInit(): void {
        this.$scope.$watch('model.productId', (productId: any) => {
            const parsedId = Number(productId) || null;
            this.$scope.variant = this.$scope.variants.find(variant => variant.id === parsedId);

            this.$scope.variantDescription =
                this.$scope.variant && this.$scope.variant.description ? this.$sce.trustAsHtml(this.$scope.variant.description) : null;

            if (this.$scope.variant && typeof window.KlarnaOnsiteService !== 'undefined') {
                ng.element('#klarna-product-placement').attr('data-purchase-amount', `${this.$scope.variant.price*100}`);
                window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
            }

            this.$location.search('variant', productId).replace();
        });

        this.$scope.$watch('model.quantity', (quantity: any) => {
            const parsedQuantity = parseInt(quantity);
            this.$scope.invalid = isNaN(parsedQuantity) || parsedQuantity <= 0;
        });
        
        this.$scope.$on('$locationChangeSuccess', () => {
            this.$scope.model.productId = this.$location.search().variant || null;

            if (this.$scope.model.productId) {
                this._slideToVariant(this.$scope.model.productId);
            }
        });
    }

    addItem(itemId: number, quantity: number): void {
        this.$scope.loading = true;
        this._cartService
            .addItem(itemId, quantity)
            .then(
                cart => {
                    UIkit.offcanvas('#cart').show();
                     const item = cart.items.find(i => i.productId == itemId);
                     this._trackService.trackAddToCart(item, quantity);
                },
                error => {
                    this._bulletinService.exception(error);
                }
            )
            .finally(() => {
                this.$scope.loading = false;
            });
    }

    private _slideToVariant(variantId: number): void {
        const $links = ng.element('#product-slidenav a[data-variant=' + variantId + ']:first');

        if ($links.length === 1) {
            $links.trigger('click');
        }
    }
}

ng.module('shop').controller('ProductController', ProductController);
