import { ServiceModule } from './module';

export class FormatService {
    static $inject: string[] = [];

    formatCurrency(num: number, decimals: number): string {
        return Intl.NumberFormat('sv-SE', {
            currency: 'SEK',
            style: 'currency',
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals,
        }).format(num);
    }
}

ServiceModule.service('FormatService', FormatService);
