import * as ng from 'angular';

import { ICart, ICheckout } from '../models';
import { CartService } from './cart.service';

export class CheckoutService {
    static $inject: string[] = ['$http', '$q', 'CartService', 'state'];

    constructor(private $http: ng.IHttpService, private $q: ng.IQService, private _cartService: CartService) {}

    get(cartId: string): ng.IPromise<ICheckout> {
        const defer = this.$q.defer<ICheckout>();

        if (!cartId) {
            defer.resolve(null);
        } else {
            this.$http<ICheckout>({
                method: 'GET',
                url: '/umbraco/api/checkout/get',
                params: {
                    cartId: cartId,
                },
            }).then(result => {
                defer.resolve(result.data);
            }, defer.reject);
        }

        return defer.promise;
    }

    update(cart: ICart): ng.IPromise<ICheckout> {
        const defer = this.$q.defer<ICheckout>();

        this._cartService.update(cart).then(cart => {
            // Retrieve the checkout instead of the cart
            this.get(cart.id).then(defer.resolve, defer.reject);
        }, defer.reject);

        return defer.promise;
    }
}

ng.module('services').service('CheckoutService', CheckoutService);
