import * as ng from 'angular';

import { ICart, ICartItem, IOrder, ItemType } from '../models';

declare global {
    interface Window {
        /** Google Analytics data layer */
        dataLayer?: any[];
        Cookiebot?: {
            consent?: {
                /**
                 * True if current user has accepted statistics cookies. The property is read only.
                 */
                statistics?: boolean;

                /**
                 * True if current user has accepted necessary cookies. The property is read only.
                 */
                necessary?: boolean;

                /**
                 * True if current user has accepted preference cookies. The property is read only.
                 */
                preferences?: boolean;

                /**
                 * True if current user has accepted marketing cookies. The property is read only.
                 */
                marketing?: boolean;
            };
            /**
             * True if the user has accepted cookies. The property is read only.
             */
            consented?: boolean;
            /**
             * True if the user has declined the use of cookies. The property is read only.
             */
            declined?: boolean;
            /**
             * True if the user has responded to the dialog with either 'accept' or 'decline'. The property is read only.
             */
            hasResponse?: boolean;
            /**
             * True if the user has enabled the web browser's 'Do not track' (DNT) setting.
             * If DNT is enabled Cookiebot will not set the third party cookie CookieConsentBulkTicket used for bulk consent.
             * The property is read only.
             */
            doNotTrack?: boolean;
        };
    }
}

/** A tracking event */
interface ITrackEvent {
    /** The unique id for the event */
    event: string;

    /** The properties for the event */
    [key: string]: any;

    /** A callback when the event completed */
    eventCallback?: () => void;
}

/**
 * A service for tracking shop flow for the user.
 */
export class TrackService {
    static $inject: string[] = ['$window', '$q'];

    private _acceptedTracking: boolean = false;
    private _enqueued: ITrackEvent[] = [];
    private _currency: string = 'SEK'; // TODO: get from settings

    constructor(private $window: Window, private $q: ng.IQService) {
        $window.addEventListener('CookiebotOnAccept', (e) => {
            if (window.Cookiebot.consent.statistics) {
                this._acceptedTracking = true;
                this._process();
            }
        }, false);
        this._acceptedTracking = !!$window.Cookiebot?.consent?.statistics;
    }

    trackImpression(product: any): void;
    trackImpression(products: any[]): void;
    trackImpression(products: any | any[]): void {
        // TODO: implement
        if (Array.isArray(products)) {
            // Multiple products
        } else {
            // Single product
        }
    }

    /**
     * Track a customer purchase, should only be called once per order
     * @param order The order to track
     */
    trackPurchase(order: IOrder): void {
        const shippingItem = order.orderItems.find((oi) => oi.itemType === ItemType.Shipping);
        this.queue({
            event: 'eec.purchase',
            ecommerce: {
                currencyCode: this._currency,
                purchase: {
                    actionField: {
                        id: order.id, // Transaction ID. Required for purchases and refunds.
                        affiliation: 'Online',
                        revenue: order.totalAmount.toFixed(2), // Total transaction value (incl. tax and shipping)
                        tax: order.vatAmount.toFixed(2),
                        shipping: shippingItem ? shippingItem.totalAmount.toFixed(2) : '0',
                    },
                    products: order.orderItems
                        .filter((oi) => oi.itemType === ItemType.Item)
                        .map((item) => {
                            return {
                                name: item.productName,
                                variant: item.variantName,
                                price: item.totalAmount.toFixed(2),
                                quantity: item.quantity,
                            };
                        }),
                },
            },
        });
    }

    /**
     * Track a change in quantity for an item
     * @param item The item the change is for
     * @param change The amount of change to track, e.g. 1 for +1 in quantity
     */
    trackQuantityChange(item: ICartItem, change: number): void {
        if (change < 0) {
            this.trackRemoveFromCart(item, change);
        } else if (change > 0) {
            this.trackAddToCart(item, change);
        }
    }

    /**
     * Track that the user is processing to the cart
     * @param cart The cart to track for
     */
    trackToCheckout(cart: ICart): ng.IPromise<void> {
        const defer = this.$q.defer<void>();

        if (this._acceptedTracking) {
            this.queue({
                event: 'eec.checkout',
                ecommerce: {
                    currencyCode: this._currency,
                    checkout: {
                        products: cart.items.map((item) => {
                            return {
                                name: item.productName,
                                variant: item.variantName,
                                price: item.totalAmount.toFixed(2),
                                quantity: item.quantity,
                            };
                        }),
                    },
                },
                eventCallback: () => {
                    defer.resolve();
                },
            });
        } else {
            defer.resolve();
        }

        defer.resolve();
        return defer.promise;
    }

    /**
     * Track that the user has added an item to the cart and with what quantity the user has added
     * @param item The item the used added to the cart
     * @param quantity The quantity the user added to the cart
     */
    trackAddToCart(item: ICartItem, quantity: number): void {
        this.queue({
            event: 'eec.addToCart',
            ecommerce: {
                currencyCode: this._currency,
                add: {
                    products: [
                        {
                            name: item.productName,
                            id: item.productId.toString(),
                            price: ((item.totalAmount / item.quantity) * quantity).toFixed(2),
                            variant: item.variantName,
                            quantity: quantity.toString(),
                        },
                    ],
                },
            },
        });
    }

    /**
     * Track that the user has removed an item from the cart
     * @param item The item the user removed
     * @param quantity The quantity the user removed
     */
    trackRemoveFromCart(item: ICartItem, quantity: number): void {
        this.queue({
            event: 'eec.removeFromCart',
            ecommerce: {
                currencyCode: this._currency,
                add: {
                    products: [
                        {
                            name: item.productName,
                            id: item.productId.toString(),
                            price: ((item.totalAmount / item.quantity) * quantity).toFixed(2),
                            variant: item.variantName,
                            quantity: quantity.toString(),
                        },
                    ],
                },
            },
        });
    }

    /**
     * Processes the event queue.
     * If the user hasn't accepted tracking nothing is done until the user changes the tracking consent.
     */
    private _process() {
        if (!this._acceptedTracking) {
            return;
        }

        // Google datalayer tracking
        for (var i = 0; i < this._enqueued.length; i++) {
            if (this.$window.dataLayer) {
                this.$window.dataLayer.push(event);
            }
        }

        this._enqueued.splice(0, this._enqueued.length);
    }

    /**
     * Queue a new tracking event
     * @param event The event to queue
     */
    queue(event: ITrackEvent) {
        this._enqueued.push(event);
        this._process();
    }
}

ng.module('services').service('TrackService', TrackService);
