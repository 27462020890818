import * as ng from 'angular';
import * as UIkit from 'uikit';

import { BulletinService, CartService, TrackService } from '../services';

interface ISimpleProductControllerScope extends ng.IScope {
    loading: boolean;
}

class SimpleProductController implements ng.IController {
    static $inject: string[] = ['$scope', 'CartService', 'BulletinService', 'TrackService'];

    constructor(
        private $scope: ISimpleProductControllerScope,
        private _cartService: CartService,
        private _bulletinService: BulletinService,
        private _trackService: TrackService,
    ) {}

    addItem(itemId: number, quantity: number): void {
        this.$scope.loading = true;
        this._cartService
            .addItem(itemId, quantity)
            .then(
                cart => {
                    UIkit.offcanvas('#cart').show();
                    const item = cart.items.find(i => i.productId == itemId);
                    this._trackService.trackAddToCart(item, quantity);
                },
                error => {
                    this._bulletinService.exception(error);
                },
            )
            .finally(() => {
                this.$scope.loading = false;
            });
    }
}

ng.module('shop').controller('SimpleProductController', SimpleProductController);
