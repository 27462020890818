export enum ItemType {
    Item = 0,
    Shipping = 1,
}

export interface IOrderItem {
    id: number;
    productId?: number;
    variantName: string;
    productName: string;
    vatAmount: number;
    totalAmount: number;
    mpn: string;
    sku: string;
    quantity: number;
    quantityUnit?: string;
    imageUrl?: string;
    productUrl?: string;
    unitWeightInGrams: number;
    orderId: number;
    vatRate: number;
    itemType: ItemType;
}

export enum OrderStatus {
    Cart = 0,
    Created = 10,
    Validated = 15,
    PendingPayment = 20,
    Paid = 30,
    Deleted = -1,
}

export interface IOrder {
    id: number;
    cartId: string;
    vatAmount: number;
    totalAmount: number;
    createDate: string;
    orderPlacedDate?: string;
    paymentReference: string;
    paymentServiceId: string;
    orderStatus: OrderStatus;
    paymentDate?: string;
    orderItems: IOrderItem[];
}
