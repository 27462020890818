// prettier-ignore-start
//// -- 3rd party libraries
import 'flexboxgrid/dist/flexboxgrid.css';
import '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';

// -- Uikit
import '../node_modules/uikit/dist/js/uikit-icons.js';
//import '../node_modules/uikit/src/js/core/uikit-video.js';
import '../node_modules/uikit/dist/js/uikit-core.js';

// -- Site imports
import './site.less';
import './scripts/site';

// -- Global exports to make them visible in the app
import 'expose-loader?angular!angular';
import 'expose-loader?$!jquery';
import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';

// - Svg icons
import './assets/images/cart.svg';
import './assets/images/truck.svg';

// -- jQuery validation for Umbraco forms
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

// -- Angular components
import './components';
// prettier-ignore-end

import './assets/fonts/cormorant-garamond-v14-latin-500.eot';
import './assets/fonts/cormorant-garamond-v14-latin-500.woff';
import './assets/fonts/cormorant-garamond-v14-latin-500.woff2';
import './assets/fonts/cormorant-garamond-v14-latin-500.ttf';
import './assets/fonts/cormorant-garamond-v14-latin-500.svg';

import './assets/fonts/cormorant-garamond-v14-latin-regular.eot';
import './assets/fonts/cormorant-garamond-v14-latin-regular.woff';
import './assets/fonts/cormorant-garamond-v14-latin-regular.woff2';
import './assets/fonts/cormorant-garamond-v14-latin-regular.ttf';
import './assets/fonts/cormorant-garamond-v14-latin-regular.svg';

import './assets/fonts/open-sans-v27-latin-300.eot';
import './assets/fonts/open-sans-v27-latin-300.woff';
import './assets/fonts/open-sans-v27-latin-300.woff2';
import './assets/fonts/open-sans-v27-latin-300.ttf';
import './assets/fonts/open-sans-v27-latin-300.svg';

import './assets/fonts/open-sans-v27-latin-300italic.eot';
import './assets/fonts/open-sans-v27-latin-300italic.woff';
import './assets/fonts/open-sans-v27-latin-300italic.woff2';
import './assets/fonts/open-sans-v27-latin-300italic.ttf';
import './assets/fonts/open-sans-v27-latin-300italic.svg';

import './assets/fonts/open-sans-v27-latin-500.eot';
import './assets/fonts/open-sans-v27-latin-500.woff';
import './assets/fonts/open-sans-v27-latin-500.woff2';
import './assets/fonts/open-sans-v27-latin-500.ttf';
import './assets/fonts/open-sans-v27-latin-500.svg';

import './assets/fonts/open-sans-v27-latin-600.eot';
import './assets/fonts/open-sans-v27-latin-600.woff';
import './assets/fonts/open-sans-v27-latin-600.woff2';
import './assets/fonts/open-sans-v27-latin-600.ttf';
import './assets/fonts/open-sans-v27-latin-600.svg';

import './assets/fonts/open-sans-v27-latin-regular.eot';
import './assets/fonts/open-sans-v27-latin-regular.woff';
import './assets/fonts/open-sans-v27-latin-regular.woff2';
import './assets/fonts/open-sans-v27-latin-regular.ttf';
import './assets/fonts/open-sans-v27-latin-regular.svg';

import './assets/fonts/open-sans-v27-latin-regular.eot';
import './assets/fonts/open-sans-v27-latin-regular.woff';
import './assets/fonts/open-sans-v27-latin-regular.woff2';
import './assets/fonts/open-sans-v27-latin-regular.ttf';
import './assets/fonts/open-sans-v27-latin-regular.svg';

import './assets/fonts/quicksand-v28-latin-300.eot';
import './assets/fonts/quicksand-v28-latin-300.woff';
import './assets/fonts/quicksand-v28-latin-300.woff2';
import './assets/fonts/quicksand-v28-latin-300.ttf';
import './assets/fonts/quicksand-v28-latin-300.svg';

import './assets/fonts/quicksand-v28-latin-500.eot';
import './assets/fonts/quicksand-v28-latin-500.woff';
import './assets/fonts/quicksand-v28-latin-500.woff2';
import './assets/fonts/quicksand-v28-latin-500.ttf';
import './assets/fonts/quicksand-v28-latin-500.svg';

import './assets/fonts/quicksand-v28-latin-regular.eot';
import './assets/fonts/quicksand-v28-latin-regular.woff';
import './assets/fonts/quicksand-v28-latin-regular.woff2';
import './assets/fonts/quicksand-v28-latin-regular.ttf';
import './assets/fonts/quicksand-v28-latin-regular.svg';
