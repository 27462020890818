import * as ng from 'angular';
import { Subscription } from 'rxjs';
import * as UIkit from 'uikit';

import { ICart } from '../models';
import { CartService, FormatService, BulletinService, TrackService } from '../services';
import { ShopModule } from './module';

interface IShopControllerScope extends ng.IScope {
    cart?: ICart;
    totalQuantity: () => number;
    initializing: boolean;
}

export class ShopController implements ng.IController {
    static $inject: string[] = ['$scope', 'CartService', 'FormatService', 'BulletinService', 'TrackService'];
    private _subscriptions: Subscription[] = [];

    constructor(
        private $scope: IShopControllerScope,
        private _cartService: CartService,
        private _formatService: FormatService,
        private _bulletinService: BulletinService,
        private _trackService: TrackService,
    ) {
        this.$scope.cart = null;
        this.$scope.initializing = true;
    }

    $onInit(): void {
        this._cartService.getSessionCart(false).then((cart: ICart) => {
            this.$scope.cart = cart;
        });

        this._subscriptions.push(
            this._cartService.cart$.subscribe((cart: ICart) => {
                this.$scope.cart = cart;
                this.$scope.initializing = false;
            }),
        );
    }

    $onDestroy(): void {
        this._subscriptions.forEach(s => !s.closed && s.unsubscribe());
    }

    totalQuantity(): number {
        let quantity = 0;

        if (this.$scope.cart) {
            for (var i = 0; i < this.$scope.cart.items.length; i++) {
                var item = this.$scope.cart.items[i];
                quantity += item.quantity;
            }
        }

        return quantity;
    }

    formatNumber(num: number): string {
        return this._formatService.formatCurrency(num, 0);
    }

    toggleCart(event?: Event) {
        if (event) {
            event.preventDefault();
        }
        UIkit.offcanvas('#cart').show();
    }

    addItem(itemId: number, quantity: number) {
        this._cartService.addItem(itemId, quantity).then(
            cart => {
                this.toggleCart();
                const item = cart.items.find(i => i.productId == itemId);
                this._trackService.trackAddToCart(item, quantity);
            },
            error => {
                this._bulletinService.exception(error);
            }
        );
    }
}

ShopModule.controller('ShopController', ShopController);
